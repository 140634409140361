<template>
  <!-- add contract dialog -->
  <Dialog header="Компетенции" :visible="modalData.display" :closable="false" :modal="true"
          :style="{width: '30vw'}">
    <div class="my-3">
      <ul>
        <li v-for="(score, index) of modalData.events_competencies" :key="index">{{score.name}}</li>
      </ul>
    </div>
    <Button label="Закрыть" icon="pi pi-times" @click="closeOrNot(0)" class="p-button-text"/>
  </Dialog>
</template>
<script>
export default {
  name: "ModalWindow",
  props: {
    modalData: {
      type: Object
    },
  },
  methods: {
    closeOrNot(is_close){
      this.$emit("closeModal", is_close)
    }
  }
}
</script>

<style scoped>

</style>