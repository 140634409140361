<template>
  <div class="container">
    <h4 class="text-center my-4">Список мероприятий</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">
      <Button icon="pi pi-plus" label="Добавить" class="p-button-rounded p-button-primary m-1"
              @click="openAddEventModal()"/>
      <DialogWindow :modalData="modalData" @closeModal="closeModal"></DialogWindow>
      <DataTable :value="events_form.events" showGridlines stripedRows
                 :globalFilterFields="['name']"
                 v-model:filters="filters"
                 :paginator="true"
                 :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 responsiveLayout="scroll"
                 currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}">
        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search"/>
              <InputText v-model="filters['global'].value" placeholder="Поиск мероприятия"/>
            </span>
          </div>
        </template>
        <Column header="№" :style="{width: '60px'}">
          <template #body="{index}">
            {{ index + 1 }}
          </template>
        </Column>
        <Column field="name" header="Название мероприятия"></Column>
        <Column header="Компетенции">
          <template #body="{data}">
            <Button label="Show" icon="pi pi-external-link" @click="openModal(data.events_id)">Посмотреть компетенции</Button>
          </template>
        </Column>
        <Column header="Место проведения">
          <template #body="{data}">
            <p v-if="data.location !== null">{{data.location}}</p>
            <p v-else>{{data.room_name}}</p>
          </template>
        </Column>
        <Column header="Дата начала">
          <template #body="{data}">
            {{ data.start_date.substr(0, 16) }}
          </template>
        </Column>
        <Column field="end_date" header="Дата конца">
          <template #body="{data}">
            {{ data.end_date.substr(0, 16) }}
          </template>
        </Column>
        <Column header="Дата начала регистрации">
          <template #body="{data}">
            {{ data.registration_start_date.substr(0, 10) }}
          </template>
        </Column>
        <Column header="Дата конца регистрации">
          <template #body="{data}">
            {{ data.registration_end_date.substr(0, 10) }}
          </template>
        </Column>
        <Column field="student_event_registration" header="Количество зарегистрированных студентов"></Column>
        <Column header="Статус ЦМП">
          <template #body="{data}">
            {{statuses.find(i => i.status_id === Number(data.status)).status_name}}
          </template>
        </Column>
        <Column header="Статус Диспетчера">
          <template #body="{data}">
            {{statuses.find(i => i.status_id === Number(data.confirm_status)).status_name}}
          </template>
        </Column>
        <Column>
          <template #body="{data}">
            <div class="d-flex flex-row">
              <Button :id="data.events_id" label="Secondary" class="p-button-secondary me-1 crud-buttons" @click="changeEventData(data.events_id)"><i class="pi pi-pencil"></i></Button>
              <Button :id="data.events_id" label="Danger" class="p-button-danger crud-buttons" @click="deleteEvents(data.events_id)"><i class="pi pi-trash"></i></Button>
            </div>
          </template>
        </Column>
      </DataTable>

      <!-- add or update grant modal -->
      <Dialog header="Добавление мероприятия"
              v-model:visible="displayAddEventModal"
              :style="{width: '98%', maxWidth: '900px'}" :modal="true" :closable="false">
        <div class="form-row my-3">
          <div class="form-group row mt-3">
            <label for="name" class="col-md-3 col-form-label">
              Название мероприятия
            </label>
            <div class="col-md-9">
              <input id="name" type="text" class="form-control"
                     placeholder="Название мероприятия" v-model="eventModel.name">
            </div>
          </div>

          <div class="form-group row mt-3">
            <label for="event_type_id" class="col-md-3">Тип</label>
            <div class="col-md-9">
              <select class="form-control form-select" id="event_type_id"
                      v-model="eventModel.event_type_id">
                <option :value="0" disabled selected hidden>Выберите тип</option>
                <option v-for="(item, index) in events_form.eventType" :value="item.id" :key="index">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-3">
            <label for="description" class="col-md-3">Описание</label>
            <div class="col-md-9">
              <textarea id="description" v-model="eventModel.description"
                        class="form-control" rows="5"
                        placeholder="Описание"></textarea>
            </div>
          </div>

          <div class="form-group row mt-3">
            <label for="description" class="col-md-3">Развитие компетенций</label>
            <div class="col-md-9">
              <ul>
                <li v-for="(item, index) in events_form.competencies"
                    :key="index">
                  {{ item.name }}
                  <input class="form-check-input" type="checkbox"
                         :value="item.id"
                         v-model="eventModel.competencies">
                </li>
              </ul>
            </div>
          </div>

          <div class="form-group row mt-3">
            <label for="organization_name" class="col-md-3">Организатор</label>
            <div class="col-md-9">
              <input id="organization_name" type="text" class="form-control" disabled v-model="organization.name">
            </div>
          </div>

          <div class="form-group row mt-3">
            <label for="organization_id" class="col-md-3">Участники</label>
            <div class="col-md-9">
              <div class="row row-cols-2 mb-3">
                <div class="col mb-2 pe-0" v-for="(item, index) in eventModel.members"
                     :key="index">
                  <InputText style="opacity: 1; color: #29292e" class="organization-members-input" disabled
                             :value="`${item.barcode} ${item.last_name} ${item.first_name}`"/>
                  <Button icon="pi pi-times" class="p-button-danger" @click="deleteMember(item.user_id)"/>
                </div>
                <div class="col">
                  <select v-if="isAllMembersDisabled" class="form-control form-select" id="members"
                          @change="memberSelect(selectedUserId)"
                          v-model="selectedUserId">
                    <option :value="null" disabled selected hidden>Выберите участников</option>
                    <option v-for="(item, index) in members" :value="item.user_id" :disabled="item.isDisabled === true" :hidden="item.isDisabled === true" :key="index">{{ item.barcode }} {{ item.last_name }} {{ item.first_name }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row mt-3">
            <label for="start_date" class="col-md-3 col-form-label">Дата начала</label>
            <div class="col-md-9">
              <input id="start_date" type="datetime-local" class="form-control"
                     v-model="eventModel.start_date">
            </div>
          </div>

          <div class="form-group row mt-3">
            <label for="end_date" class="col-md-3 col-form-label">Дата окончания</label>
            <div class="col-md-9">
              <input id="end_date" type="datetime-local" class="form-control"
                     v-model="eventModel.end_date">
            </div>
          </div>

          <div class="form-group row mt-3">
            <label for="registration_start_date" class="col-md-3 col-form-label">Дата начала регистрации</label>
            <div class="col-md-9">
              <input id="registration_start_date" type="date" class="form-control"
                     v-model="eventModel.registration_start_date">
            </div>
          </div>

          <div class="form-group row mt-3">
            <label for="registration_end_date" class="col-md-3 col-form-label">Дата окончания регистрации</label>
            <div class="col-md-9">
              <input id="registration_end_date" type="date" class="form-control"
                     v-model="eventModel.registration_end_date">
            </div>
          </div>

          <div class="form-group row mt-3">
            <label for="room_id" class="col-md-3">Место проведения</label>
            <div class="col-md-9">
              <SelectButton @click="clearLocationData" class="mb-2" v-model="venueOption" :options="placeVariants" aria-labelledby="single"/>

              <select v-if="venueOption === 'Кабинет'"
                      :disabled="eventModel.end_date === null || eventModel.start_date === null || eventModel.end_date === '' || eventModel.start_date === ''"
                      style="color: #29292e !important;"
                      class="form-control form-select" id="location"
                      v-model="eventModel.room_id">
                <option :value="null" disabled selected hidden>Выберите аудиторию</option>
                <option v-for="(item, index) in free_rooms_for_events" :value="item.id" :key="index">{{ item.name }}</option>
              </select>
              <input v-else
                     :disabled="eventModel.end_date === null || eventModel.start_date === null || eventModel.end_date === '' || eventModel.start_date === ''"
                     type="text" class="form-control placeInput" placeholder="Задать свое место проведения"
                     v-model="eventModel.location">
            </div>
          </div>
        </div>
        <template #footer>
          <Button label="Отмена" icon="pi pi-times" @click="closeAddEventModal" class="p-button-text"/>
          <Button label="Создать" icon="pi pi-check"
                  :loading="loadingSave"
                  autofocus
                  @click="saveEvents "/>
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {FilterMatchMode, FilterOperator} from "primevue/api";
import DialogWindow from "@/components/events/DialogWindow.vue"

export default {
  name: 'EventsList',
  components: {
    DialogWindow,
  },
  data() {
    return {
      loading: true,
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
      },
      placeVariants: ['Кабинет', 'Свое'],
      venueOption: 'Кабинет',
      eventModel: {},
      events: [],
      displayAddEventModal: false,
      loadingSave: false,
      selectedUserId: null,
      isDialogVisible: false,
      statuses: [
        {status_id: 0, status_name: 'В ожиданий'},
        {status_id: 1, status_name: 'Подтверждено'},
        {status_id: 2, status_name: 'Отказано'}
      ],
      modalData: {
        display: false,
        events_id: null,
        events_competencies: []
      }
    }
  },
  computed: {
    ...mapState('events', ['events_form', 'free_rooms_for_events']),
    ...mapState('organization', ['organizations', 'organization', 'members']),
    isAllMembersDisabled(){
      if (Array.isArray(this.members)){
        return !this.members.every(i => i.isDisabled === true)
      }
      return true
    }
  },

  watch: {
    async 'eventModel.start_date'(newValue) {
      if (this.eventModel.start_date !== null && this.eventModel.end_date !== null) {
        await this.GET_FREE_ROOMS_FOR_EVENTS({
          start_date: this.eventModel.start_date,
          end_date: this.eventModel.end_date
        })
      }
    },
    async 'eventModel.end_date'(newValue) {
      if (this.eventModel.start_date !== null && this.eventModel.end_date !== null) {
        await this.GET_FREE_ROOMS_FOR_EVENTS({
          start_date: this.eventModel.start_date,
          end_date: this.eventModel.end_date
        })
      }
    }
  },

  methods: {

    ...mapActions('events', ['GET_EVENTS', 'GET_EVENT_TYPE', 'GET_COMPETENCIES', 'DELETE_EVENTS', 'UPDATE_EVENTS',  'POST_EVENTS', 'GET_FREE_ROOMS_FOR_EVENTS']),
    ...mapActions('organization', ['GET_ORGANIZATION_MEMBERS', 'GET_ORGANIZATIONS', 'GET_ORGANIZATION']),

    openModal(event_id) {
      this.modalData = {
        display: true,
        events_id: event_id,
        events_competencies: this.events_form.events.find(i => i.events_id === event_id).competencies,
      }
    },

    clearModalData() {
      this.modalData = {
        display: false,
        events_id: null,
        events_competencies: []
      }
    },

    closeModal(is_close){
      if (is_close === 0) {
        this.clearModalData()
      }
    },

    memberSelect(user_id){
      this.eventModel.members.push(this.members.find(item => item.user_id === user_id))
      this.members.find(item => item.user_id === user_id).isDisabled = true
      this.selectedUserId = null
    },

    deleteMember(user_id){
      this.eventModel.members.splice(this.eventModel.members.findIndex(item => item.user_id === user_id), 1)
      this.members.find(item => item.user_id === user_id).isDisabled = false
      this.selectedUserId = null
    },

    async deleteEvents(id) {
      let res = await this.DELETE_EVENTS(id)
      if (res) {
        await this.GET_EVENTS()
        this.$message({title: 'Успешно удалено'})
      }
    },

    async openAddEventModal() {
      this.displayAddEventModal = true
      await this.GET_ORGANIZATION_MEMBERS();
      for (let i = 0; i < this.members.length; i++){
        this.members[i].isDisabled = false
      }
      this.eventModel = {
        name: '',
        event_type_id: 0,
        room_id: null,
        location: null,
        description: '',
        start_date: null,
        end_date: null,
        registration_start_date: null,
        registration_end_date: null,
        competencies: [],
        members: [],
        organization: this.organization.name,
        organization_id: this.organization.id
      }
    },

    async saveEvents() {
      this.loadingSave = true
      if (this.eventModel.name !== ''
          && this.eventModel.event_type_id !== 0
          && (this.eventModel.room_id !== null
              || this.eventModel.location !== '')
          && this.eventModel.description !== ''
          && this.eventModel.start_date !== null
          && this.eventModel.end_date !== null
          && this.eventModel.registration_start_date !== null
          && this.eventModel.registration_end_date !== null
          && this.eventModel.competencies.length !== 0
          && this.eventModel.members.length !== 0) {
        this.displayAddEventModal = false
        console.log('this.eventModel', this.eventModel)
        if (this.eventModel.events_id === undefined){
          let postRes = await this.POST_EVENTS(this.eventModel)
          if (postRes) {
            await this.GET_EVENTS()
            this.$message({title: 'Успешно сохранено'})
          }
        } else {
          let updateResult = await this.UPDATE_EVENTS({'id': this.eventModel.events_id, 'events_modal': this.eventModel})
          if (updateResult) {
            await this.GET_EVENTS()
            this.$message({title: 'Успешно сохранено'})
          }
        }
        this.loadingSave = false
      } else {
        this.loadingSave = false
        this.$error({title: 'Заполните все поля!'})
      }
    },

    async changeEventData(events_id){
      this.displayAddEventModal = true
      await this.GET_ORGANIZATION_MEMBERS();
      const eventsData = this.events_form.events.find(item => item.events_id === events_id)

      for(let i = 0; i < this.members.length; i++){
        let member = eventsData.members.find(member => member.user_id === this.members[i].user_id)
        if (member){
          this.members[i].isDisabled = true
        }
      }

      console.log(eventsData.room_id, eventsData.location)

      if (eventsData.room_id !== null){
        this.venueOption = this.placeVariants[0]
      } else {
        this.venueOption = this.placeVariants[1]
      }

      let eventCompetencies = eventsData.competencies.map(item => item.competencies_id)
      this.eventModel = {
        events_id: eventsData.events_id,
        event_type_id: eventsData.event_type_id,
        name: eventsData.name,
        description: eventsData.description,
        room_id: eventsData.room_id,
        location: eventsData.location,
        start_date: eventsData.start_date.substr(0, 16),
        end_date: eventsData.end_date.substr(0, 16),
        registration_start_date: eventsData.registration_start_date.substr(0, 10),
        registration_end_date: eventsData.registration_end_date.substr(0, 10),
        competencies: eventCompetencies,
        members: eventsData.members,
        organization: this.organization.name
      }
    },

    closeAddEventModal() {
      this.displayAddEventModal = false
    },

    clearLocationData(){
      if (this.venueOption === 'Кабинет'){
        console.log(this.venueOption, this.eventModel.location, this.eventModel.room_id)
        this.eventModel.location = null
      } else {
        console.log(this.venueOption, this.eventModel.location, this.eventModel.room_id)
        this.eventModel.room_id = null
      }
    }
  },

  async mounted() {
    await this.GET_EVENT_TYPE()
    await this.GET_COMPETENCIES()
    await this.GET_EVENTS()
    await this.GET_ORGANIZATION()

    this.loading = false
  },
}

</script>

<style scoped>
.organization-members-input {
  width: 87%;
}

.crud-buttons {
  padding: 12px;
}
</style>
